import React from 'react'

function Form15() {
    return (
        <div>

            <section className="w3l-form-12">
                
                    <div className="container1a py-md-3">
                        <div className="grid grid-column-2 py-md-5">

                            <div className="column2">
                                <div className="row">
                                    <div className="col-md-3 col-sm-6 col-6">
                                        <a href="/MicrosoftCertificationsCard"><div className="courses-item">
                                            {/* <span className="fa fa-male"></span> */}
                                            <p><img src="assets/images/icon/A.jpg" className="img-responsive" alt="Best Azure Online Training" />Interior/Exterior </p>
                                        </div></a>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-6">
                                        <a href="/AWSCertificationsCard"><div className="courses-item">
                                            {/* <span className="fa fa-suitcase"></span> */}
                                            <p><img src="assets/images/icon/A1.jpg" className="img-responsive" alt="Best AWS Solution Architect Training" />Industrial Floor</p>
                                        </div></a>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-6 mt-md-0 mt-4">
                                        <a href="/GoogleCertificationsCard"><div className="courses-item">
                                            {/* <span className="fa fa-code"></span> */}
                                            <p><img src="assets/images/icon/G.png" className="img-responsive" alt="Best GCP Online Training" />Sand Blasting</p>
                                        </div></a>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-6 mt-md-0 mt-4">
                                        <a href="/DevOpsCertificationsCard"><div className="courses-item">
                                            {/* <span className="fa fa-flask"></span> */}
                                            <p><img src="assets/images/icon/D.png" className="img-responsive" alt="Best DevOps Online Training" />Line Striping</p>
                                        </div></a>
                                    </div>
                                 
                                    <div className="paypalCenter">
                                        <div id="smart-button-container" className="paypal col-md-12 col-sm-12 col-12 mt-4">
                                            <div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="paypalCenter">
                                    {/* <div className="elfsight-app-6909a4b7-e4d4-457a-9674-5d366b92adc4"></div> */}
                                </div>
                            </div>
                        </div>

                        {/* <div>
                            <div className="elfsight-app-6909a4b7-e4d4-457a-9674-5d366b92adc4"></div>
                        </div> */}
                    </div>
                
            </section>
        </div>
    )
}

export default Form15
