import React from 'react'
import { Link } from "react-router-dom"

function News() {
    return (
        <div>

            <section className="w3l-price-2" id="news">
                <div className="price-main py-5">
                    <div className="container py-md-3">
                        <div className="pricing-style-w3ls row py-md-5">
                            <div className="pricing-chart col-lg-6">
                                <h3 className="">Our Speciality Service</h3>
                                <div className="tatest-top mt-md-5 mt-4">
                                    <div className="price-box btn-layout bt6">
                                        <div className="grid grid-column-2">
                                            <div className="column-6">
                                                <img src="assets/images/certifiedcloudprofessional.jpg" alt="Google" className="img-fluid" />
                                            </div>
                                            <div className="column1">

                                                <div className="job-info">
                                                    <h6 className="pricehead"><a href="#">Interior Painting </a></h6>
                                                    {/* <h5>April 25, 2020</h5> */}
                                                    <p>
                                                    Our Interior Design Service, a tailor-made solution that offers design expert consultation, personalized designs, unparalleled project management and hassle-free execution, you can transform your space into the home of your dreams.

ids-painting-lp
                                                </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="price-box btn-layout bt6 top-middle-1">
                                        <div className="grid grid-column-2">
                                            <div className="column-6">
                                                <img src="assets/images/certified-oracle-admin.jpg" alt="GCP" className="img-fluid" />
                                            </div>
                                            <div className="column1">
                                                <div className="job-info">
                                                    <h6 className="pricehead"><a href="#">
                                                        Rust Encapsulation</a></h6>
                                                    {/* <h5>March 25, 2020</h5> */}
                                                    <p>
                                                    Rust Encapsulator is the best rust product to apply over surfaces that might vary in grades of rust, have bare metal or old paint present.
                                        </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price-box btn-layout bt6">
                                        <div className="grid grid-column-2">
                                            <div className="column-6">
                                                <img src="assets/images/certified-devops-prof.jpg" alt="Best Azure Training" className="img-fluid" />
                                            </div>
                                            <div className="column1">

                                                <div className="job-info">
                                                    <h6 className="pricehead"><a href="#">
                                                        Exterior Painting  </a></h6>
                                                    {/* <h5>February 25, 2020</h5> */}
                                                    <p>
                                                    Are you planning to give a new look to your home exterior walls? Browse through our collections of exterior paint colours crafted specially to enhance your ...
                                        </p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="text-right mt-4">
                                    <a href={`assets/images/speciality.pdf`} className="btn btn-secondary btn-theme2">View All </a>
                                </div>
                            </div>
                            <div className="w3l-faq-page col-lg-6 pl-3 pl-lg-5 mt-lg-0 mt-5">
                                <h3 className="">Industrial Service</h3>
                                <div className="events-top mt-md-5 mt-4">
                                    <div className="events-top-left">
                                        <img src="assets/images/special-team.jpg" alt="Job Placement" className="img-fluid"  />
                                        {/* <div className="icon-top">
                                        <h3>20</h3>
                                        <p>Nov</p>
                                        <span>2020</span>
                                    </div> */}
                                    </div>
                                    <div className="events-top-right">
                                        <h6 className="pricehead">
                                            <a href="#">Line Striping</a>
                                        </h6>
                                        <p className="mb-2 mt-3">
                                       
                                    </p>
                                        {/* <li>07:00 - 10:00 </li>
                                    <li className="melb">Melbourne, Australia</li> */}
                                    </div>
                                </div>
                                <div className="events-top mt-4">
                                    <div className="events-top-left">
                                        <img src="assets/images/special-team-assit.jpg" alt="Placement" className="img-fluid" />
                                        {/* <div className="icon-top">
                                        <h3>25</h3>
                                        <p>Nov</p>
                                        <span>2020</span>
                                    </div> */}
                                    </div>
                                    <div className="events-top-right">
                                        <h6 className="pricehead">
                                            <a href="#">	Sand Blasting </a>
                                        </h6>
                                        <p className="mb-2 mt-3">
                                      
                                    </p>
                                        {/* <li>07:00 - 10:00 </li>
                                    <li className="melb">Melbourne, Australia</li> */}
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        
                                
                         

                                
                                


                        

                                
                                
                        
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

export default News
