import React from 'react'
import { Link } from "react-router-dom"
import SoftwarePricingTable from './SoftwarePricingTable';
import Player1 from './Player1'
import CardsTranning from './CardsTranning6';

function AboutMain1() {
	return (
		<div>
			
				
			<section className="w3l-index1" id="about">
				<div className="calltoaction-20  py-5 editContent">
					<div className="container1a py-md-3">

						<div className="calltoaction-20-content row">
							<div className="column center-align-self col-lg-6 pr-lg-5">
								<h1 className="editContent">Mission Statement</h1>
								<section className="w3l-feature-1 .features" id="features">
								<p className="more-gap editContent" >
								
								We pride ourselves on PAINTING CLIENT’S ENVISIONED FUTURE. We challenge ourselves on every project byoptimizing client’s schedule, inventing innovative ideas,and minimizing project duration through our adequately trained and highly proficient painting team thereby  exceeding our client’s expectations.


								</p>
								
							
								</section>
								<br /> <br /> <br />
								
								
								
								{/* <a className="btn btn-secondary btn-theme2 mt-3" href="about.html"> Read More</a> */}
								
							</div>
							<div className="column ccont-left col-lg-6">
								
								<Player1 />
								
							
							</div>
						</div>
					</div>
				</div>
			</section>

		</div>
	)
}

export default AboutMain1
