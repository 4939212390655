import React from 'react'

function Testimonials() {
  return (
    <div>
      <section className="w3l-customers-4" id="testimonials">
        <div id="customers4-block" className="py-5">
          <div className="container py-md-3">

            <div className="section-title align-center row">
              <div className="item-top col-md-6 pr-md-5">
                <div className="heading">
                  <h3 className="head text-white">Hear what our Customer have to say</h3>
                  <p className="my-3 head text-white">
                    
                  Thank you so much for your beautiful creation!! It was a real work of art!! Everybody loved it and gave me tons of compliments to give them to you!! [My daughter] loved it!! Her UT friends loved it!!! You delivered my vision 100%!! The combination of Colours and Quality is fabulous!! Your Team work is  perfect and beautiful!! Thank you so much!!
                        <br />
                    <img src="assets/images/raghu.jpg" className="img-responsive" alt="Python" />
                  </p>
                  <div><a title="Customer reviews of lydiapainting.com" href="https://www.webwiki.com/lydiapainting.com"><img src="https://www.webwiki.com/etc/rating/widget/1329518423/techpledgeconsulting-com-rating-round-150.png" alt="Reviews of lydiapainting.com" /></a></div>
                  <p className="my-3 head text-white"></p>
                </div>
              </div>
              <div className="item-top col-md-6 mt-md-0 mt-4">
                <div className="item text-center">
                  <div className="imgTitle">
                    <img src="assets/images/c3.jpg" className="img-responsive" alt="Development" />
                  </div>
                  <h6 className="mt-3">Eswar Rao</h6>
                  <p className="">Customer</p>
                  <p>
                  The exterior paint was looking FANTASTIC!!! I have to tell you, the painting service was AWESOME!!. I was so impressed!!! So was my husband, and everyone at praised about the colour! Thank you for making our house colourful and beautiful. 
                          </p>
                  

                </div>
              </div>
            </div>
          </div>
        </div>


      </section>

      <section id="testimonials">
        
          
          {/* <div className="container py-md-3">
            <div className="section-title align-center row">
              <div className="item-top col-md-12 pr-md-5">
                <div className="heading">
                </div>
              </div>


            </div> 
        </div>*/}
        


      </section>
    </div >
  )
}

export default Testimonials
