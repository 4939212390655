import React from 'react'
import { Link } from "react-router-dom"
import ReactPlayer from 'react-player'
import { Helmet } from 'react-helmet'
import Player from './Player'

function Project() {

	return (
		
		<div>
			 <Helmet>
        		<title>Cakes</title>
				<meta name="description" content="Learn cloud and DevOps from the real-life consultant. We are masters in all the disciplines like Microsoft Azure Solution Architect, Microsoft Azure Administrator, Microsoft Azure DevOps, AWS Solution Architect, and DevOps Automation. " ></meta>
				<meta name='og:title' content='TechPledge, Leaders in Online It Training'></meta>

					<meta name='og:url' content='https://www.lydiapainting.com/about'></meta>

					<meta name='og:description' content='Learn cloud and DevOps from the real-life consultant. We are masters in all the disciplines like Microsoft Azure Solution Architect, Microsoft Azure Administrator, Microsoft Azure DevOps, AWS Solution Architect, and DevOps Automation.'>
					</meta>
					<meta name='url' content='https://www.lydiapainting.com/about'></meta>
					<meta name='distribution' content='Global'></meta>
					<meta name="twitter:image" content="https://www.lydiapainting.com/assets/images/Logo.jpg">
					</meta>

					<meta name="keywords" content="AZ-204, AZ-400 , AZ 400 , AZ 204, AZ-500, AZ-500, AZ-303, DP-200,DP 201, technologies, new technologies, the new technology, Best devops online training, react native training, python,react training, az 400 certification">
					</meta>
      		</Helmet>
			<section className="w3l-about-breadcrum">
				<div className="breadcrum-bg py-sm-5 py-4">
					
					<div className="container py-lg-3">
						<h2>Project</h2>
						<p><h3>Home/ Project</h3></p>
					</div>
				</div>
			</section>
			{/* <!-- content-with-photo4 block --> */}
			<section className="w3l-index1" id="about">
				<div id="content-with-photo4-block" className="pt-5">
					<div className="container1a py-md-3">
						<div className="cwp4-two row">
							
							
							<p className="my-3 head text-center text-nowra mx-auto" >
							<h1 className="editContent">Some of our Top Project</h1>
							</p>	
								
							</div>
							
						</div>
					</div>
				
			</section>
			{/* <!-- content-with-photo4 block --> */}
			
			
			
							{/* <div className="col-lg-2 col-sm-7">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/t1.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Shruti Sinha</h4>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-7 top-tem">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/t2.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Christian Anyanwu</h4>
										 <ul className="d-flex justify-content-center py-3 social-icons">
											<li className="effect-soc-team1">
												<a href="#">
													<span className="fa fa-facebook-f"></span>
												</a>
											</li>
											<li className="effect-soc-team2">
												<a href="#">
													<span className="fa fa-twitter"></span>
												</a>
											</li>
											<li className="effect-soc-team3">
												<a href="#">
													<span className="fa fa-google-plus"></span>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-7  mt-sm-0 mt-5">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/t3.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Poornima Arun</h4>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-7 top-tem">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/t4.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Bipeen Sinha</h4>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-7  mt-sm-0 mt-5">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/t5.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Aditi Srivastava</h4>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-7 top-tem">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/t6.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Berlin Donald</h4>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-sm-7 top-tem">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/LorenMind.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Loren Mind</h4>
									</div>
								</div>
							</div>

							<div className="col-lg-2 col-sm-7 top-tem">
								<div className="team-grid text-center">
									<div className="team-img">
									</div>
									<div className="team-info">
										<h4></h4>
									</div>
								</div>
							</div>

							<div className="col-lg-2 col-sm-7  mt-sm-0 mt-5">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/devesh.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Devesh Srivastava</h4>
									</div>
								</div>
							</div>

							<div className="col-lg-2 col-sm-7 top-tem">
								<div className="team-grid text-center">
									<div className="team-img">

									</div>
									<div className="team-info">
										<h4></h4>
									</div>
								</div>
							</div>


							<div className="col-lg-2 col-sm-7 top-tem">
								<div className="team-grid text-center">
									<div className="team-img">
										<img className="img-fluid rounded" src="assets/images/WilliemBob.jpg" alt="" />
									</div>
									<div className="team-info">
										<h4>Williem Bob</h4>
									</div>
								</div>
							</div>

							<div className="col-lg-2 col-sm-7 top-tem">
								<div className="team-grid text-center">
									<div className="team-img">

									</div>
									<div className="team-info">
										<h4></h4>
									</div>
								</div>
							</div> */}

<div className="elfsight-app-c46b27f7-9d1f-426b-8b8f-f108025abc54"></div>

				
		</div>	

		
	)
}

export default Project;
