import React from 'react'

function Demo() {
    return (
        <div>
            <section className="w3l-index1" id="about">
				<div className="calltoaction-20  py-5 editContent">
					<div className="container py-md-4">
					
						<div className="calltoaction-20-content row">
							<div className="column center-align-self col-lg-12 pr-lg-5">
								<h3 className="editContent"><b>Fill out the form to place the order..</b></h3><br />
								<h4 className="more-gap editContent">
								We get trusted by many customers since last five years. Our  services have <b>Five stars </b>rating. Reach out to us to get you started on your next project. Any sized job, We are dedicated to meeting client expectations by delivering excellent service and quality craftsmanship. Our Customer Success Team are here to help, providing customized painting experiance  
                                             whenever it's needed. We are very flexible with  times and will work around .
								</h4><br />
								<p>Call US: +1 (214) 244-4423</p>
 								<p>Eamil US: info@lydiapainting.com</p>
                                </div>
								<div className="elfsight-app-99d18053-791b-4e23-a372-1cbb6bcc56db"></div>
							
						</div>
					</div>
				</div>
			</section>

        </div>
    )
}

export default Demo
