import React from 'react'
import { Link } from "react-router-dom"
import ReactPlayer from 'react-player'
import { Helmet } from 'react-helmet'
import Player from './Player'

function About() {

	return (
		
		<div>
			 <Helmet>
        		<title>Lydia Painting</title>
				<meta name="description" content="Learn cloud and DevOps from the real-life consultant. We are masters in all the disciplines like Microsoft Azure Solution Architect, Microsoft Azure Administrator, Microsoft Azure DevOps, AWS Solution Architect, and DevOps Automation. " ></meta>
				<meta name='og:title' content='TechPledge, Leaders in Online It Training'></meta>

					<meta name='og:url' content='https://www.lydiapainting.com/about'></meta>

					<meta name='og:description' content='Learn cloud and DevOps from the real-life consultant. We are masters in all the disciplines like Microsoft Azure Solution Architect, Microsoft Azure Administrator, Microsoft Azure DevOps, AWS Solution Architect, and DevOps Automation.'>
					</meta>
					<meta name='url' content='https://www.lydiapainting.com/about'></meta>
					<meta name='distribution' content='Global'></meta>
					<meta name="twitter:image" content="https://www.lydiapainting.com/assets/images/Logo.jpg">
					</meta>

					<meta name="keywords" content="AZ-204, AZ-400 , AZ 400 , AZ 204, AZ-500, AZ-500, AZ-303, DP-200,DP 201, technologies, new technologies, the new technology, Best devops online training, react native training, python,react training, az 400 certification">
					</meta>
      		</Helmet>
			<section className="w3l-about-breadcrum">
				<div className="breadcrum-bg py-sm-5 py-4">
					
					<div className="container py-lg-3">
						<h2>About Us</h2>
						<p><h3>Home/ About</h3></p>
					</div>
				</div>
			</section>
			{/* <!-- content-with-photo4 block --> */}
			<section className="w3l-index1" id="about">
				<div id="content-with-photo4-block" className="pt-5">
					<div className="container1a py-md-3">
						<div className="cwp4-two row">
							
							<div className="cwp4-text col-lg-6">
								<h1><b>About Our Organisation</b></h1>
								<p className="my-3 head text-justify text-nowra mx-auto" >Lydia Painting, LLC is a Dallas-Fort Worth-based commercial and industrial painting company, licensed and liability insured with workers' compensation. We are dedicated to meeting client expectations by delivering  excellent service and quality craftsmanship, from quick  clean-up jobs to detail-oriented high-end projects. We 
also believe in developing a trusted relationship with our customers through our highly experienced painters and offer rental repaints of offices and living spaces.
								</p>
								<p className="my-3 head text-justify text-nowra mx-auto" >
								<b>Mission:</b>
We pride ourselves on PAINTING CLIENT’S ENVISIONED  FUTURE. We challenge ourselves on every project by optimizing client’s schedule, inventing innovative ideas,and minimizing project duration through our adequately  trained and highly proficient painting team thereby  exceeding our client’s expectations.
								</p>
								
								<br />
								
							</div>
							<hr />
							<div className="cwp4-image col-lg-6 pl-lg-4 mt-lg-0 mt-6">
								<Player />
							
															
														
								
								
								
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- content-with-photo4 block --> */}
			
		
			<section className="w3l-feature-2">
				<div className="grid top-bottom py-5">
					<div className="container1a py-md-5">
						<div className="heading text-center mx-auto">
							<h3 className="head" >Why Choose Lydia Paintaing </h3>
							<p className="my-3 head text-justify text-nowra mx-auto" ><h5>Discover the wide range of interior & exterior Wall Paint services such as our all time favourite Texture Wall Paint, Wainscoting, Mural Art, and many other creative custom wall paint design just for you
								</h5></p>
							 
					</div>
						<div className="middle-section row mt-5 pt-3">
							<div className="three-grids-columns col-lg-3 col-sm-6 text-center ">
								<div className="icon"> <span className="fa fa-graduation-cap" aria-hidden="true"></span></div>
								<h4>Licensed Company </h4>
								<p className="my-3 head text-justify text-nowra mx-auto" > We are a Licensed company that offers exceptional painting qualities with highly experienced and adequately trained teams.
 </p>
							</div>
							<div className="three-grids-columns col-lg-3 col-sm-6 mt-sm-0 mt-5 text-center">
								<div className="icon"> <span className="fa fa-user" aria-hidden="true"></span></div>
								<h4>Safety</h4>
								<p className="my-3 head text-justify text-nowra mx-auto" >We provide professional services through our insurance, bonds, and the use of right equipment.
.</p>
							</div>
							<div className="three-grids-columns col-lg-3 col-sm-6 mt-lg-0 mt-5 text-center">
								<div className="icon"> <span className="fa fa-globe fa-5x" aria-hidden="true"></span></div>
								<h4> Loyal Customers</h4>
								<p className="my-3 head text-justify text-nowra mx-auto" >We provide top-notch painting services for HOA's,
restaurants, hotels, schools, libraries, churches,
factories, warehouses, retail stores, medical
facilities, corporate offices, and much more.
 we have a loyal customer base that swears by the taste and keeps coming back for more. </p>
							</div>
							
						</div>
					</div>
				</div>
			</section>
			
				
	

		</div >
	)
}

export default About
