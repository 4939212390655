import React from 'react'


function SoftwarePricingTable() {
    return (
        <div>
            <section className="w3l-index1" id="about">
                <div className="elfsight-app-2971ad25-7949-4796-bf4f-96ed2788c2fc"></div>
            </section>

        </div>
    )
}

export default SoftwarePricingTable
