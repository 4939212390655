import React from 'react'

function Placements() {
    return (
        <div>
            <section className="w3l-features-1">
                <div className="features py-12">
                    <div className="container pb-5">
                        <div className="fea-gd-vv row ">	
                                <div className="float-lt feature-gd col-lg-10 col-sm-6">	
                                        <div className="icon-info"> <br /><br />
                                            <h1> Placement Process</h1>
                                        </div>					 
                                </div>	
                        </div>  
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Placements
