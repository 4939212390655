import React from 'react'
import {Link} from "react-router-dom"

function Contact() {
    const topFunction = () =>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    

    const mapStyles = {
        frameborder:0,
        allowfullscreen:""
      };
    return (
        <div>
            {/* <!-- Top Menu 1 --> */}
                <section className="w3l-contact-breadcrum">
                <div className="breadcrum-bg py-sm-5 py-4">
                    <div className="container py-lg-3">
                    <h2>Contact Us</h2>
                    <p><Link to="/" >Home</Link> / Contact</p>
                    </div>
                </div>
                </section>
                {/* <!-- contact --> */}
                <section className="w3l-contacts-12" id="contact">
                    <div className="contact-top pt-5">
                        <div className="container py-md-3">
                            
                            <div className="row cont-main-top">
                            
                                {/* <!-- contact address --> */}
                                <div className="contact col-lg-4">
                                    <div className="cont-subs">
                                        <div className="cont-add">
                                            

                                      
                                        <div className="cont-add-rgt">
                                            <div className="cont-add-lft">
                                                <span className="fa fa-map-marker" aria-hidden="true"></span>
                                            </div>
                                            <p><img src="assets/images/american-flag.png" height="60px" width="80px" className="img-responsive" alt="AZ-500"/></p>
                                            <h4>Address:</h4>
                                            <p className="contact-text-sub">
                                            Lydia Painting LLC 1st Floor, 12015 Hesse Drive,Farmers Branch,TX75234
216.855.4785 
                                            </p>
                                            
                                            <br />
                                            <hr></hr>
                                            <div className="cont-add-lft">
                                                <span className="fa fa-map-marker" aria-hidden="true"></span>
                                            </div>
                                            <p><img src="assets/images/american-flag.png" height="60px" width="80px" className="img-responsive" alt="AZ 500"/></p>
                                            <h4>Address:</h4>
                                            <p className="contact-text-sub">
                                            Lydia Painting , 9550 forest lane Dallas TX 75243 suite 462, Dallas Texas, Zip Code 75243
                                            </p>
                                            <br />
                                            <hr></hr>
                                            <div className="cont-add-lft">
                                                <span className="fa fa-map-marker" aria-hidden="true"></span>
                                            </div>
                                            
                                         
                                        </div>
                                      
                                    </div>
                                        <div className="cont-add add-2">
                                            
                                        <div className="cont-add-rgt">
                                            <h4>Email:</h4>
                                            <a href="mailto:info@example.com">
                                                <p className="contact-text-sub"> info@lydiapainting.com </p>
                                            </a>
                                        </div>
                                        <div className="cont-add-lft">
                                            <span className="fa fa-envelope" aria-hidden="true"></span>
                                    </div>
                                    </div>
                                        <div className="cont-add">
                                        
                                            <div className="cont-add-rgt">
                                                <h4>Phone:</h4>
                                                <a href="tel:+7-800-999-800">
                                                    <p className="contact-text-sub">+1 (214) 244-4423</p>
                                                </a>
                                            </div>
                                            <div className="cont-add-lft">
                                                <span className="fa fa-phone" aria-hidden="true"></span>
                                        </div>
                                        </div>
                                        <div className="cont-add add-3">
                                        
                                            <div className="cont-add-rgt">
                                                <h4>Find Us On</h4>
                                                <div className="main-social-1 mt-2">
                                                    <a href="https://www.facebook.com/TechPledgeConsulting/" className="facebook"><span className="fa fa-facebook"></span></a>
                                                    <a href="https://twitter.com/TechPledgeInfo" className="twitter"><span className="fa fa-twitter"></span></a>
                                                    <a href="https://www.youtube.com/watch?v=9bU9NFCQ4IY" className="youtube"><span className="fa fa-youtube"></span></a>
                                                    <a href="#google-plus" className="google-plus"><span className="fa fa-google-plus"></span></a>
                                                    <a href="https://www.linkedin.com/company/techpledge-consulting-services/" className="linkedin"><span className="fa fa-linkedin"></span></a>
                                                </div>
                                            </div>
                                            <div className="cont-add-lft">
                                            
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- //contact address -->
                                <!-- contact form --> */}
                                <div className="contacts12-main col-lg-8 mt-lg-0 mt-5">
                                    <div className="elfsight-app-99d18053-791b-4e23-a372-1cbb6bcc56db"></div>
                                    {/* <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="main-input">
                                        <div className="top-inputs d-grid">
                                            <input type="text" placeholder="Name" name="w3lName" id="w3lName" aria-required />
                                            <input type="email" name="email" placeholder="Email" id="w3lSender" aria-required />
                                                {/* <select name="Country" placeholder="Country" id="Country"  >
                                                    <option value="USA">USA</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="India">India</option>
                                                    <option value="UK">UK</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Tanzania">Tanzania</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Saudi">Saudi</option>
                                                    <option value="UAE">UAE</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Others">Others</option>
                                            </select> 
                                        </div>

           
                                        
                                         <select name="Course" placeholder="Course" id="Course"  className="form-control-lg  d-grid">
                                                <option value="Course">Course</option>
                                                <option value="AWS">AWS</option>
                                                <option value="AZURE">AZURE</option>
                                                <option value="DevOps">DevOps</option>
                                                <option value="Servers">Servers</option>
                                                <option value="Language">Language</option>
                                                <option value="Database">Database</option>
                                                <option value="Others">Others</option>
                                        </select>
                                        <input type="text" placeholder="Phone Number" name="w3lName" id="w3lName" aria-required />
                                        <textarea placeholder="Message" name="w3lMessage" id="w3lMessage" aria-required></textarea>
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-theme2">Submit Now</button>
                                        </div>
                                    </form> */}
                                </div>
                                {/* <!-- //contact form --> */}
                            </div>
                        </div>
                        <hr></hr>
                    {/* <!-- /features --> */}
                        <div className="features py-4">
                            <div className="container pb-5">
                            
                            <div className="fea-gd-vv row ">	
                            <div className="float-lt feature-gd col-lg-4 col-sm-6">	
                                    
                                    
                                </div>	
                                <div className="float-mid feature-gd col-lg-4 col-sm-6 mt-sm-0 mt-4">	
                                    
                                    
                            </div> 
                                <div className="float-rt feature-gd col-lg-4 col-sm-6 mt-lg-0 mt-4">	
                                    
                                    
                            </div>	 
                                
                        </div>  
                        </div>
                    </div>
                        {/* <!-- map --> */}
                        <div className="map">
                        {/* https://www.digitalocean.com/community/tutorials/how-to-integrate-the-google-maps-api-into-react-applications */}
                            <iframe
                                src="https://goo.gl/maps/sRcgcuArZNrXHFYs9"
                                ></iframe>
                                {/* frameborder="0" 
                                allowfullscreen="" */}
                        </div>
                        {/* <!-- //map --> */}
                    </div>
                </section>
    
       </div>
    )
}

export default Contact
